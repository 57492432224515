<template>

    <v-bottom-navigation inset absolute app
                         dark
                         shift
                         grow
                         height="60"
                         background-color="primary"

    >
        <v-btn v-for="(item, index) in navigationItems" :key="index"
               v-show="item.visible"

               @click="menuClick(item.to)"
               >

            <v-icon small>{{ item.icon }}</v-icon>
            <span>{{ $t(item.title) }}</span>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
export default {
    name: "app-sidebar-footer",
    data() {
        return {
            value: this.$route.name ,
            navigationItems:
                [
                    {
                        title: 'invoices_for_payment',
                        icon: 'mdi-qrcode-scan mdi-24px',
                        to: {name: "invoice"},
                        action: this.forceRerender,
                        visible: this.can(['fitness_admin', 'fitness_cashier','fitness_accountant','park_admin', 'park_cashier','park_accountant', 'restaurant_admin','restaurant_cashier','restaurant_accountant','coffee_admin','coffee_cashier','coffee_accountant']),

                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "coffee_house"},
                        action: this.forceRerender,
                        visible: this.can(['coffee_admin','coffee_cashier','coffee_accountant']),
                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "restaurant"},
                        action: this.forceRerender,
                        visible: this.can(['restaurant_admin','restaurant_cashier','restaurant_accountant']),
                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "fitness"},
                        action: this.forceRerender,
                        visible: this.can(['fitness_admin','fitness_cashier','fitness_accountant']),
                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "cinema"},
                        action: this.forceRerender,
                        visible: this.can(['cinema_admin','cinema_cashier','cinema_accountant']),
                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "shop"},
                        action: this.forceRerender,
                        visible: this.can(['shop_admin','shop_cashier','shop_accountant']),
                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "park"},
                        action: this.forceRerender,
                        visible: this.can(['park_admin','park_cashier','park_accountant']),
                        exact: false
                    },
                    {
                        title: 'orders',
                        icon: 'mdi-cart-variant mdi-24px',
                        to: {name: "orders", type: "hotel"},
                        action: this.forceRerender,
                        visible: this.can(['hotel_admin','hotel_cashier','hotel_accountant']),
                        exact: false
                    },

                    {
                        title: 'tables',
                        icon: 'mdi-table-chair mdi-24px',
                        to: {name: "tables_coffee_shop"},
                        action: this.forceRerender,
                        visible: this.can(['coffee_admin1']),
                        exact: false
                    },
                    {
                        title: 'setting',
                        icon: 'mdi-cog-box mdi-24px',
                        to: {name: "setting_coffee_house"},
                        action: this.forceRerender,
                        visible: this.can(['coffee_admin']),
                        exact: false
                    },

                    {
                        title: 'tables',
                        icon: 'mdi-table-chair mdi-24px',
                        to: {name: "tables_restaurant"},
                        action: this.forceRerender,
                        visible: this.can(['restaurant_admin']),
                        exact: false
                    },

                    {
                        title: 'reserve_room',
                        icon: 'mdi-content-paste mdi-24px',
                        to: {name: "reserve_room"},
                        action: this.forceRerender,
                        visible: this.can(['hotel_admin','hotel_cashier']),
                        exact: false
                    },

                    {
                        title: 'setting',
                        icon: 'mdi-cog-box mdi-24px',
                        to: {name: "setting_restaurant"},
                        action: this.forceRerender,
                        visible: this.can(['restaurant_admin']),
                        exact: false
                    },

                    {
                        title: 'setting',
                        icon: 'mdi-cog-box mdi-24px',
                        to: {name: "setting_fitness"},
                        action: this.forceRerender,
                        visible: this.can(['fitness_admin']),
                        exact: false
                    },

                    {
                        title: 'products',
                        icon: 'mdi-shopping mdi-24px',
                        to: {name: "product"},
                        action: this.forceRerender,
                        visible: this.can(['shop_admin']),
                        exact: false
                    },

                    {
                        title: 'setting',
                        icon: 'mdi-cog-box mdi-24px',
                        to: {name: "setting_shop"},
                        action: this.forceRerender,
                        visible: this.can(['shop_admin']),
                        exact: false
                    },

                    {
                        title: 'setting',
                        icon: 'mdi-cog-box mdi-24px',
                        to: {name: "setting_cinema"},
                        action: this.forceRerender,
                        visible: this.can(['cinema_admin']),
                        exact: false
                    },
                    {
                        title: 'setting',
                        icon: 'mdi-cog-box mdi-24px',
                        to: {name: "setting_hotel"},
                        action: this.forceRerender,
                        visible: this.can(['hotel_admin']),
                        exact: false
                    },


                ],
        };
    },
    methods: {
        menuClick(route){
            let name = route.name;
            let type = route.type;
            if(this.$route.name !== name) {
                if(type !== ''){
                    this.$router.push({
                        name: name,
                        params: {type : type}
                    })
                }
                else{
                    this.$router.push({
                        name: name,
                    })
                }

            }


        }
    }
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default{
    height: inherit !important;
}
.theme--dark.v-btn.v-btn--has-bg {
    background-color: transparent !important;
}
</style>


